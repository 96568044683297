/* @font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'),
    url(../fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'),
    url(../fonts/OpenSans/OpenSans-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'),
    url(../fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
  font-weight: 700;
} */

/* body,
html {
  font-family: 'Open Sans', sans-serif !important;
  background-color: var(--main-background-color);
} */

/* * {
  color: var(--text-color);
} */

.main-container {
  margin: 0px auto;
  width: 100%;
  max-width: var(--container-width);
  padding: 8px var(--container-horizontal-padding) 0px;
}

.grid-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
}

.grid-container.halfwidth {
  width: calc(50% + 16px);
  margin: auto;
}

.grid-item {
  padding-left: 16px;
  padding-top: 16px;
}

.grid-item.fullwidth {
  flex-basis: 100%;
}

.grid-item.halfwidth {
  flex-basis: 50%;
}

.srOnly {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

progress {
  --_track: hsl(228 100% 90%);
  --_track-size: min(10px, 1ex);
  --_progress: hsl(228 100% 50%);
  --_radius: 1e3px;
  --_indeterminate-track: linear-gradient(
    to right,
    var(--_track) 45%,
    var(--_progress) 0%,
    var(--_progress) 55%,
    var(--_track) 0%
  );
  --_indeterminate-track-size: 225% 100%;
  --_indeterminate-track-animation: progress-loading 2s infinite ease;

  /*  reset  */
  appearance: none;
  border: none;
  width: 100%;

  /*  custom style  */
  position: relative;
  height: var(--_track-size);
  border-radius: var(--_radius);
  overflow: hidden;

  @media (prefers-color-scheme: dark) {
    --_track: hsl(228 20% 30%);
    --_progress: hsl(228 100% 75%);
  }

  &:focus-visible {
    outline-color: var(--_progress);
  }

  /*  indeterminate  */
  &::after {
    content: '';
    inset: 0;
    position: absolute;
    background: var(--_indeterminate-track);
    background-size: var(--_indeterminate-track-size);
    background-position: right;
    animation: var(--_indeterminate-track-animation);
  }

  /*  indeterminate Safari  */
  &::-webkit-progress-bar {
    background: var(--_indeterminate-track);
    background-size: var(--_indeterminate-track-size);
    background-position: right;
    animation: var(--_indeterminate-track-animation);
  }

  /*  indeterminate Firefox  */
  &::-moz-progress-bar {
    background: var(--_indeterminate-track);
    background-size: var(--_indeterminate-track-size);
    background-position: right;
    animation: var(--_indeterminate-track-animation);
  }
}

@keyframes progress-loading {
  50% {
    background-position: left;
  }
}
