:root {
  /* colors */
  --white: #fff;
  --main-color: #2b6bd5;
  --main-color-hover: #6394e7;
  --secondary-color: #cfe6f7;
  --text-color: #3e4041;
  --text-color-lighter: #5a6677;
  --main-background-color: #f6f6f6;
  --gray-lighter: #f9f9f9;

  /* font size */
  --text-size: 14px;
  --text-size-medium: 16px;
  --text-size-h1: 24px;
  --text-size-h2: 24px;
  --text-size-h3: 20px;

  /* container */
  --container-width: 1280px;
  --container-horizontal-padding: 16px;

  /*buttons*/
  --button-vertical-padding: 8px;
  --button-horizontal-padding: 24px;
  --button-padding: var(--button-vertical-padding)
    var(--button-horizontal-padding);

  /*radius*/
  --default-border-radius: 4px;

  --default-drop-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  --table-padding: 16px;
}
