.root {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  margin: 0px;
  border: 0px;
  vertical-align: top;
  width: 100%;
}

.root .fullwidth {
  flex-basis: 100%;
}

.root .inputLabel {
  color: rgba(0, 0, 0, 0.6);
  padding: 0px;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate(14px, 16px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
  user-select: none;
}

.root .inputLabelDisabled {
  color: rgba(0, 0, 0, 0.38);
}

.root .inputLabelError {
  color: red;
}

.root .helperTextError {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin: 3px 14px 0px;
  color: red;
}

.root .inputLabelRetracted {
  max-width: calc(133% - 32px);
  transform: translate(14px, -9px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
}

.root .inputBase {
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border: 0px;
  border-radius: 4px;
}

.root .inputBaseDisabled {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
}

.root .inputFieldset {
  text-align: left;
  position: absolute;
  inset: -5px 0px 0px;
  margin: 0px;
  padding: 0px 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.root .inputFieldsetError {
  border-color: red;
}

.root .inputFieldsetFocused {
  border-width: 2px;
}

.root .inputFieldsetLegend {
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0px;
  height: 11px;
  visibility: hidden;
  max-width: 100%;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  white-space: nowrap;
}

.root .inputFieldsetLegendFocused {
  max-width: 0.01px;
}

.root .input {
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.root .input:focus {
  outline: none;
}

.root .inputDisabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  background-color: rgba(0, 0, 0, 0.08);
  cursor: not-allowed;
}
